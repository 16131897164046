import React, { useContext, useEffect, useState } from "react";
import { Checkbox, Card, List, Typography, Button, Input } from "antd";
import "./ScreenerForm.css";
import MainContext from "../../contexts/mainContext";
import { TiArrowRightOutline } from "react-icons/ti";

const { Text } = Typography;

const maQuestions = [
  { id: "massHealth", text: "Do you have MassHealth?" },
  { id: "childrenUnder5", text: "Do you have children under 5?" },
  { id: "childrenUnder19", text: "Do you have children under 19?" },
  { id: "socialSecurityNumber", text: "Do you have a SSN?" },
];

const txQuestions = [
  { id: "ssn", text: "Do you have an SSN?" },
  { id: "medicaid", text: "Do you have Medicaid?" },
  { id: "childrenUnder5", text: "Do you have children under 5 years old?" },
  { id: "childrenUnder18", text: "Do you have children under 18 years old?" },
  { id: "over65", text: "Are you over 65 years old?" },
  { id: "monthlyIncome", text: "Total monthly household income ($)", type: "number", min: 0 },
  { id: "householdSize", text: "Household size", type: "number", min: 1 },
];

const ScreenerForm = ({ user, handleUpdate }) => {
  const { loc } = useContext(MainContext);
  const [formState, setFormState] = useState({});
  const [results, setResults] = useState({});
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (loc === "ma") {
      setQuestions(maQuestions);
      setFormState({
        massHealth: false,
        childrenUnder5: false,
        childrenUnder19: false,
        socialSecurityNumber: false,
      });
    } else if (loc === "tx") {
      setQuestions(txQuestions);
      setFormState({
        ssn: false,
        medicaid: false,
        childrenUnder5: false,
        childrenUnder18: false,
        over65: false,
      });
    }
  }, [loc]);

  const handleToggle = (id) => {
    setFormState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const getIncomeLimits = (size) => {
    const fpl = 1215 + (size - 1) * 430;
    return {
      snap: fpl * 1.3,
      wic: fpl * 1.85,
      tanf: [78, 163, 188, 226, 251][size - 1] || 251,
      lifeline: fpl * 1.35,
      eitc: [17640, 46560, 52918, 56838][Math.min(size - 1, 3)] || 0,
    };
  };

  const calculateResults = () => {
    const { ssn, medicaid, childrenUnder5, childrenUnder18, over65, householdSize, monthlyIncome } = formState;

    if (loc === "tx" && (!householdSize || householdSize < 1 || monthlyIncome === undefined || monthlyIncome === "")) {
      alert("Please enter both household size (minimum 1) and monthly income before calculating.");
      return;
    }

    let newResults = {
      wic: false,
      lifeline: false,
      eitc: false,
      snap: false,
      tanf: false,
      msp: false,
      tafdc: false,
      noMatch: false,
    };

    if (loc === "tx") {
      if (!ssn) {
        newResults.noMatch = "An SSN is required for all programs.";
      } else {
        const limits = getIncomeLimits(householdSize || 1);
        const income = parseFloat(monthlyIncome) || 0;

        newResults.snap = income <= limits.snap;
        newResults.wic = (childrenUnder5 || childrenUnder18) && income <= limits.wic;
        newResults.tanf = childrenUnder5 && income <= limits.tanf;
        newResults.lifeline = income <= limits.lifeline;
        newResults.eitc = income * 12 <= limits.eitc;
        newResults.msp = over65;

        if (!Object.values(newResults).some((value) => value === true)) {
          newResults.noMatch = "No programs match your eligibility criteria.";
        }
      }
    } else if (loc === "ma") {
      const { massHealth, childrenUnder5, childrenUnder19, socialSecurityNumber } = formState;

      if (massHealth && childrenUnder5) {
        newResults.wic = true;
      }
      // Massachusetts specific program eligibility
      if (socialSecurityNumber) {
        newResults.tafdc = childrenUnder19;
        newResults.lifeline = massHealth;
        newResults.snap = !massHealth; // Simplified logic - adjust based on actual requirements
      }

      if (!Object.values(newResults).some((value) => value === true)) {
        newResults.noMatch = "No programs match your eligibility criteria.";
      }
    }

    setResults(newResults);
    const updatedResults = Object.fromEntries(Object.entries(newResults).map(([key, value]) => [`screener_result_${key}`, value]));
    const updatedStates = Object.fromEntries(Object.entries(formState).map(([key, value]) => [`screener_state_${key}`, value]));

    handleUpdate({ ...updatedStates, ...updatedResults }, false);
  };

  const renderQuestion = (question) => {
    if (question.type === "number") {
      return (
        <div key={question.id} className="checkbox-field">
          <Text strong>{question.text}</Text>
          <Input
            type="number"
            min={question.min}
            value={formState[question.id]}
            onChange={(e) =>
              setFormState((prev) => ({
                ...prev,
                [question.id]: e.target.value,
              }))
            }
          />
        </div>
      );
    }

    return (
      <div key={question.id} className="checkbox-field">
        <Checkbox checked={formState[question.id]} onChange={() => handleToggle(question.id)} style={{ fontSize: "18px", fontWeight: "bold" }}>
          {question.text}
        </Checkbox>
      </div>
    );
  };

  const continueLH = (
    <Button
      size="large"
      style={{ width: "100%" }}
      type="primary"
      onClick={() => {
        handleUpdate({ eligibilityScreenedOn: new Date().getTime() });
      }}
    >
      Continue to filling applications
    </Button>
  );
  const continueOnYTB = (
    <a
      target="_blank"
      onClick={() => handleUpdate({ eligibilityScreenedOn: new Date().getTime() })}
      href={"https://www.yourtexasbenefits.com/Learn/PartnersLogin?sysType=CBO" + "&ahd-user-id=" + user.id}
    >
      {" "}
      Continue on Your Texas Benefits
    </a>
  );
  const resultItems = [
    results.msp && { title: "MSP", description: continueOnYTB, color: "darkgreen" },
    loc === "tx" && results.snap && { title: "SNAP", description: continueOnYTB, color: "darkgreen" },
    results.tanf && { title: "TANF", description: continueOnYTB, color: "darkgreen" },
    results.tafdc && { title: "TAFDC", description: "TAFDC, Up to $447/month", color: "darkgreen" },
    results.lifeline && {
      title: "Lifeline",
      description: <b style={{ textAlign: "right" }}>for internet/phone subsidies</b>,
      color: "darkgreen",
    },
    results.wic && { title: "WIC", description: "Women, Infant, Children, $50-80/month", color: "darkgreen" },
    results.eitc && { title: "EITC", description: "Earned Income Tax Credit Program", color: "darkgreen" },
    results.getYourRefund && { title: "Get Your Refund", description: "Tax assistance program", color: "darkgreen" },
    results.noMatch && { title: "No Match Found", description: results.noMatch === true ? "No specific programs match patient's eligibility." : results.noMatch, color: "#F44336" },
  ].filter(Boolean);

  return (
    <Card className="form-container">
      <div className="checkbox-container">{questions.map((question) => renderQuestion(question))}</div>
      <Button
        type="default"
        onClick={calculateResults}
        disabled={loc === "tx" && (!formState.householdSize || formState.householdSize < 1 || formState.monthlyIncome === undefined || formState.monthlyIncome === "")}
        style={{ margin: "20px 0", width: "100%" }}
      >
        Calculate
      </Button>
      <div className="results-container" style={{ display: resultItems?.length ? "" : "none" }}>
        <List
          dataSource={resultItems}
          renderItem={(item) => (
            <List.Item className="result-item">
              <Text strong style={{ fontSize: "18px", color: item.color }}>
                {item.title}:
              </Text>
              <Text style={{ fontSize: "16px", fontWeight: "bold", color: "#333" }}>{item.description}</Text>
            </List.Item>
          )}
        />
      </div>
      <div>{resultItems?.length ? continueLH : ""}</div>
    </Card>
  );
};

export function ScreenerFormDisplay({ data }) {
  const results = Object.entries(data)
    .filter(([key, value]) => key.startsWith("screener_result_") && (value?.value === true || value === true))
    .map(([key]) => key.replace("screener_result_", ""));

  return (
    <div style={{ padding: "1rem", boarder: "1px solid black" }}>
      {results.length > 0 && (
        <>
          <strong>
            <>Eligible Programs:</>
          </strong>
          <ol style={{ textTransform: "uppercase" }}>
            {results.map((program) => (
              <li>
                <strong>{program}</strong>
              </li>
            ))}
          </ol>
          <>Note: This is based on screener that you've used.</>
        </>
      )}
    </div>
  );
}

export default ScreenerForm;
