import { addDoc, collection, doc, setDoc, updateDoc, where } from "firebase/firestore";
import { auth, db } from "../config";
import { getAllUsers } from "./get";
import { cleanObject } from "../../helpers/obj";

export async function createUser(userData, userId = null) {
  const userCollectionRef = collection(db, "User");
  const savedAmbassadorName = localStorage.getItem("ambassadorName") || "-";
  
  let docRef;

  if (userId) {
    // If userId is provided, update the existing document with that ID
    const existingDocRef = doc(userCollectionRef, userId);
    await setDoc(existingDocRef, { ...userData, ambassadorName: savedAmbassadorName }, { merge: true });
    docRef = existingDocRef;
  } else {
    // If no userId is provided, create a new document
    docRef = await addDoc(userCollectionRef, { ...userData, ambassadorName: savedAmbassadorName });
  }

  console.log("Document written with ID: ", docRef.id);
  return docRef.id;
}

const getLoc = () => {
  const currentUrl = window.location.href;
  if (currentUrl.includes("tx-")) {
    return "tx";
  } else {
    return "ma";
  }
};

export async function updateUser(userId, updatedUserData, loc) {
  if (!loc) loc = getLoc();
  const userDocRef = doc(db, "User", userId);
  console.log("doc to update", cleanObject({ ...updatedUserData, loc }));
  await updateDoc(userDocRef, cleanObject({ ...updatedUserData, loc }));
  console.log("Document updated");
}

/**
 * RUN UPDATE ALL
 *
 */

// setTimeout(() => {
//   const to_update = { loc: "ma" };
//   getAllUsers().then((users) => {
//     users?.forEach(async (user) => {
//       console.log("updating user", user);
//       await updateUser(user?.id, { ...to_update, ...user });
//     });
//   });
// }, 2000);
