import React, { useState } from "react";
import { Modal, Select, message, Input, Alert } from "antd";
import { applicationConfigs } from "../../pages/Home/home.config";

const ForceAdd = ({ user, open, setOpen, updateUser }) => {
  const [selectedApp, setSelectedApp] = useState('');
  const [name, setName] = useState('');

  const handleOk = () => {
    // Simple validation
    if (!selectedApp) {
      message.error('Please select an application!');
      return;
    }

    if (!user?.name && !name.trim()) {
      message.error('Please enter a name for the user!');
      return;
    }

    // Format and submit data
    const formattedValues = {
      [applicationConfigs[selectedApp].uploaded_key]: true,
    };

    if (name.trim()) {
      formattedValues.name = name.trim();
    }

    updateUser(formattedValues);
    setOpen(false);
    
    // Reset state
    setSelectedApp('');
    setName('');
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedApp('');
    setName('');
  };

  return (
    <Modal title="Manually Check off Application status" open={open} onOk={handleOk} onCancel={handleCancel}>
      <Alert 
        style={{ margin: "20px 0px" }} 
        message="⚠️ ONLY USE IT IF THE APPLICATION IS SUBMITTED ON THE EXTERNAL SITE BUT DIDN'T GET CAPTURED BY THE SCRAPER." 
        type="warning" 
      />
      
      {!user?.name && (
        <div style={{ marginBottom: 16 }}>
          <div>Name</div>
          <Input 
            placeholder="Enter name" 
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      )}

      <div>
        <div>Select Application to Check</div>
        <Select 
          placeholder="Select an application"
          style={{ width: '100%' }}
          value={selectedApp}
          onChange={(value) => setSelectedApp(value)}
        >
          {Object.entries(applicationConfigs).map(([key, app]) => (
            <Select.Option key={key} value={key}>
              {app.summaryName}
            </Select.Option>
          ))}
        </Select>
      </div>
    </Modal>
  );
};

export default ForceAdd;
