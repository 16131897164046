import languagesList from "languages-list";
import { calculateAge, getCurrentDate, normalizeDate } from "../../../../helpers/time";
import { splitName } from "../generateSnapPdf/get";
import { allRelations } from "./suggessions";
import { flattenJson, unflattenJson } from "../../../../helpers/obj";
import { Typography } from "antd";
import fields from "./fields";
import { incomeQuestionsConfig } from "./incomeConfig";

export const systemgeneratedanswers = (data) => {
  let nameConfig;
  if (data.first_name?.value) {
    nameConfig = [
      {
        snap_id: "Last Name_pg1",
        value: data.last_name?.value,
        type: "text",
      },
      {
        snap_id: "First Name_pg1",
        value: data.first_name?.value,
        type: "text",
      },

      {
        id: "name",
        snap_id: "Signature",
        value: `${data.first_name?.value} ${data.last_name?.value}`,
        type: "text",
      },
    ];
  } else {
    const splitted = splitName(data["name"].value ?? "");
    nameConfig = [
      {
        snap_id: "Last Name_pg1",
        value: splitted.last_name,
        type: "text",
      },
      {
        snap_id: "First Name_pg1",
        value: splitted.first_name,
        type: "text",
      },
      {
        snap_id: "Middle Name_pg1",
        value: splitted.middle_name,
        type: "text",
      },
    ];
  }
  return [
    ...nameConfig,
    {
      snap_id: "Date",
      value: getCurrentDate(),
      type: "text",
    },
  ];
};
export const lifelinequestions = [
  {
    id: "name",
    question: "What is your name?",
    type: "text",
    extractedFromLiheap: true,
    partial_liheap: true,
    snap_id: "Signature",
  },
  {
    id: "application_type",
    question: "Do you have Medicaid, Medicare, SNAP, or none of these? (Medicaid = Masshealth)",
    type: "select",
    options: ["Medicaid", "Medicare", "SNAP", "none of the above"],
    validation: {
      required: true,
      error_message: "Please select an option.",
    },
    help_text: "Select the program you are currently enrolled in, if any. Select 'none of the above' if you are not enrolled in any listed programs.",
  },
  {
    id: "ssn_last4",
    question: "Last 4 numbers of Social Security Number. (SSN)",
    type: "text",
    validation: {
      regex: "^[0-9]{4}$",
      error_message: "Please enter the last 4 digits of your Social Security Number.",
    },
    min: 4,
    max: 4,
    help_text: "Enter the last 4 digits of your SSN as found on your Social Security card.",
  },
  {
    id: "date_of_birth",
    question: "What is your date of birth?",
    type: "date",
    snap_id: "Date of Birth",
    validation: {
      format: "YYYY-MM-DD",
      required: true,
      error_message: "Please enter your date of birth in the specified format",
    },
    help_text: "For example, if your birthday is July 4, 1976, enter 07/04/1974.",
  },

  {
    id: "gender",
    question: "What is your gender?",
    type: "select",
    options: ["M", "F", "O"],
  },
  {
    id: "address",
    question: "Address:",
    type: "text",
  },
  {
    id: "city",
    question: "City:",
    type: "text",
  },
  {
    id: "state",
    question: "State:",
    type: "text",
  },
  {
    id: "zip",
    question: "Zip Code:",
    type: "text",
  },
  {
    id: "email",
    question: "What is your email?",
    type: "text",
    extractedFromLiheap: true,
    partial_liheap: true,
  },
  {
    id: "phone",
    question: "What is your phone number?",
    type: "text",
    extractedFromLiheap: true,
    partial_liheap: true,
    snap_id: "Phone Number",
  },
];

export const wicQuestions = [
  {
    id: "name",
    question: "What is your name?",
    type: "text",
    extractedFromLiheap: true,
    partial_liheap: true,
    snap_id: "Signature",
  },
  // {
  //   id: "application_type",
  //   question: "Do you have Medicaid, Medicare, SNAP, or none of these? (Medicaid = Masshealth)",
  //   type: "select",
  //   options: ["Medicaid", "Medicare", "SNAP", "none of the above"],
  //   validation: {
  //     required: true,
  //     error_message: "Please select an option.",
  //   },
  //   help_text: "Select the program you are currently enrolled in, if any. Select 'none of the above' if you are not enrolled in any listed programs.",
  // },
  // {
  //   id: "ssn_last4",
  //   question: "Last 4 numbers of Social Security Number.",
  //   type: "text",
  //   validation: {
  //     regex: "^[0-9]{4}$",
  //     error_message: "Please enter the last 4 digits of your Social Security Number.",
  //   },
  //   help_text: "Enter the last 4 digits of your SSN as found on your Social Security card.",
  // },
  {
    id: "date_of_birth",
    question: "What is your date of birth?",
    type: "date",
    snap_id: "Date of Birth",
    // validation: {
    //   format: "YYYY-MM-DD",
    //   error_message: "Please enter your date of birth in the format: YYYY-MM-DD.",
    // },
    help_text: "For example, if your birthday is July 4, 1976, enter 1976-07-04.",
  },

  // {
  //   id: "gender",
  //   question: "What is your gender? (M/F/O)",
  //   type: "text",
  // },
  {
    id: "address",
    question: "Address:",
    type: "text",
  },
  {
    id: "city",
    question: "City:",
    type: "text",
  },
  // {
  //   id: "state",
  //   question: "State:",
  //   type: "text",
  // },
  {
    id: "zip",
    question: "Zip Code:",
    type: "text",
  },
  {
    id: "email",
    question: "What is your email?",
    type: "text",
    extractedFromLiheap: true,
    partial_liheap: true,
  },
  {
    id: "phone",
    question: "What is your phone number?",
    type: "text",
    extractedFromLiheap: true,
    partial_liheap: true,
    snap_id: "Phone Number",
  },

  {
    id: "preferred_language",
    question: "What language do you prefer to speak?",
    type: "select",
    options: ["English", "Spanish", "Cantonese", "Mandarin", "French", "Portuguese", "Russian", "Arabic", "Haitian Creole", "Other"],
    value: "English",
    snap_id: "What language do you prefer to speak",
  },

  {
    id: "best_time_to_contact",
    question: "Best time to contact you?",
    type: "multi-select",
    options: ["morning", "afternoon", "late afternoon", "evening", "saturday morning"],
    // extractedFromLiheap: true,
    // partial_liheap: true,
    // snap_id: "Phone Number",
  },
];

export const liheapExtractedDocumentConfig = [
  // {
  //   id: "name",
  //   question: "Full Name",
  //   type: "text",
  //   extractedFromLiheap: true,
  //   partial_liheap: true,
  //   snap_id: "Signature",
  // },

  {
    id: "first_name",
    question: "First Name",
    type: "text",
  },

  {
    id: "last_name",
    question: "Last Name",
    type: "text",
  },
  {
    id: "address",
    question: "Address",
    type: "text",
    extractedFromLiheap: true,
    partial_liheap: true,
    snap_id: "Home Address_pg1",
  },
  {
    id: "phone",
    question: "Phone Number",
    type: "text",
    extractedFromLiheap: true,
    partial_liheap: true,
    snap_id: "Phone Number",
  },
  {
    id: "email",
    question: "Email",
    type: "text",
    extractedFromLiheap: true,
    partial_liheap: true,
  },
  {
    id: "age",
    question: "What is your age?",
    type: "text",
    type2: "number",
    extractedFromLiheap: true,
    not_required_in_snap: true,
  },
  {
    id: "gender",
    question: "Gender",
    type: "select",
    extractedFromLiheap: true,
    options: ["M", "F"],
    snap_id: (index, value) => {
      if (typeof value === "string" && value[0]?.toLowerCase() === "m") {
        return "Male";
      } else {
        return "Female";
      }
    },
  },
];
const documentConfig = [
  { type: "heading", question: "Personal Information" },
  fields.first_name,
  fields.last_name,
  fields.dob,
  // fields.preferred_language,
  {
    question: "Social Security Number (SSN)",
    id: "ssn",
    type: "text",
    help_text2: "Please leave it empty if you don't have one.",
  },
  {
    question: "Is your household homeless?",
    id: "homeless",
    required: true,
    type: "radio",
    options: [
      { value: "Yes", text: "Yes" },
      { value: "No", text: "No" },
    ],
  },
  {
    id: "disability",
    question: "Do you have a disability",
    help_text2: "A disability must be federally certified to be considered for SNAP. For example, people who receive SSI have a federally qualified disability.",
    type: "boolean",
    value: false,
  },
  fields.address,
  fields.zip,
  fields.city,
  // considering that mailing address will be same as this one
  fields.phone_number,
  fields.email,
  // emergency snap questions are not being asked, question: what default should be used instead?
  {
    question: "Are you a US citizen?",
    id: "us_citizen",
    required: true,
    type: "radio",
    options: [
      { value: "Yes", text: "Yes" },
      { value: "No", text: "No" },
    ],
    help_text2: "Even if you are not a US Citizen, you or other household members may still be eligible.",
  },
  fields.preferred_language,
  fields.gender,
  {
    question: "Ethnicity:",
    id: "ethnicity",
    required: true,
    type: "select",
    options: [
      { value: "Hispanic or Latino", text: "Hispanic or Latino" },
      { value: "Not Hispanic or Latino", text: "Not Hispanic or Latino" },
      { value: "Decline to answer", text: "Decline to answer" },
    ],
  },
  fields.race,
  { type: "heading", question: "Family Information" },

  {
    id: "live_with_others",
    question: "Do other people live with you?",
    snap_id: "Do you live with other people?",
    type: "boolean",
    value: false,
    more: {
      // ask more questions when the answer of the above question is= when
      entryText: "Person living with you",
      when: true,
      limit: 10,
      questions: [
        {
          id: "name",
          question: "Name",
          type: "text",
        },
        {
          id: "gender",
          question: "Gender",
          type: "radio",
          options: [
            { value: "Male", text: "Male" },
            { value: "Female", text: "Female" },
          ],
        },
        {
          id: "date_of_birth",
          question: "Date of Birth",
          type: "date", // Could be "date" if the system supports date formats
        },
        {
          id: "social_security_number",
          question: "Social Security Number (SSN)",
          type: "text",
          text_type: "number",
          help_text2: "Optional",
        },
        {
          id: "relationship_to_applicant",
          question: "Relationship to you?",
          type: "select",
          options: allRelations,
        },
        {
          id: "us_citizen",
          question: "Is this person a US citizen?",
          type: "boolean",
          value: false,
        },
        {
          id: "disability",
          question: "Does this person has a disability",
          help_text2: "A disability must be federally certified to be considered for SNAP. For example, people who receive SSI have a federally qualified disability.",
          type: "boolean",
          value: false,
        },
      ],
    },
  },
  {
    id: "pay_shelter_costs",
    question: "Is the household responsible to pay shelter costs?",
    snap_id: "Shelter costs",
    type: "boolean",
    value: false,
    more: {
      when: true,
      entryText: "Shelter cost",
      limit: 3,
      questions: [
        {
          id: "shelter_cost_details",
          question: "Provide details of your shelter costs:",
          type: "select",
          options: ["Rent", "Mortgage", "Property Taxes", "Home Insurance", "Condo fee", "Other"],
        },
        {
          id: "amount",
          question: "Amount",
          type: "text",
          text_type: "amount",
          placeholder: "$ _________",
        },
      ],
    },
  },
  {
    id: "pay_utility_costs",
    question: "Is the household responsible to pay utility costs separate from shelter costs?",
    type: "boolean",
    value: false,
    snap_id: "Utility costs",
    more: {
      when: true,
      limit: 1,
      questions: [
        {
          id: "utility_service_options",
          question: "Select all utility and service options applicable to your household:",
          type: "multi-select",
          options: [
            "Heat (oil, gas, electricity or propane, etc.)",
            "Electricity and/or gas (other than heating use); water; sewage; and/or trash collection",
            "Electricity for an air conditioner in the summer",
            "A fee to use an air conditioner in the summer",
            "Phone or cell phone service (including pre-paid)",
          ],
        },
      ],
    },
  },

  {
    question: "Do you need an EBT card?",
    id: "ebt_card",
    required: true,
    type: "radio",
    options: [
      { value: "Yes", text: "Yes" },
      { value: "No", text: "No" },
    ],
    help_text2: "If you have an EBT card in your name, you do not need a new one. If you click yes, your old card will be cancelled right away.",
  },
  { type: "heading", question: "Income Information" },
  {
    question: "Does anyone (including you) recieve any amount/benefits?",
    help_text2: "This is an important question. Please answer it carefully.",
    id: "earning_options",
    required: true,
    type: "boolean",
    value: false,
    more: {
      when: true,
      entryText: "Earning entry",
      limit: 20,
      questions: [
        {
          id: "Person",
          question: "Person earning this amount",
          type: "text",
        },
        {
          id: "Income type",
          question: "Income type",
          type: "select",
          options: incomeQuestionsConfig.map((item) => item.title),
        },
        {
          id: "Amount earned (Per month)",
          question: "Earnings (Per month)",
          type: "text",
          text_type: "amount",
          placeholder: "$ _________",
        },
      ],
    },
  },
];
export const timeFormat = "DD-MM-YYYY";
export default documentConfig;

const fullDocConfig = [...lifelinequestions, ...liheapExtractedDocumentConfig, ...documentConfig];
const findItemInConfig = (id) => {
  return fullDocConfig.find((item) => item.id === id);
};

export { findItemInConfig, fullDocConfig };

export function syncData(data) {
  // Ensure data is properly formatted before processing
  if (!data || typeof data !== "object") {
    throw new Error("Invalid data input");
  }

  // Normalize date of birth if available
  if (data.date_of_birth?.value) {
    try {
      data.date_of_birth.value = normalizeDate(data.date_of_birth.value);
      const ageValue = calculateAge(data.date_of_birth.value);
      if (ageValue) data.age = { value: ageValue };
    } catch (err) {
      console.error("Error normalizing date of birth:", err);
      delete data.date_of_birth;
    }
  }

  // Extract last 4 digits of SSN if available
  if (data.ssn?.value) {
    data.ssn_last4 = { value: data.ssn.value.slice(-4) };
  }

  // Construct full name if first and last names are available
  if (data.applicantFirstName?.value && data.applicantLastName?.value) {
    try {
      data.name = { value: `${data.applicantFirstName.value} ${data.applicantLastName.value}` };
    } catch (err) {
      console.error("Error constructing full name:", err);
    }
  }

  return data;
}
