export const incomeQuestionsConfig = [
    {
      title: "Self-Employment",
      question: "What is the gross income amount of {Person} self-employment? (Required)",
      description: "The gross income amount of {Person}'s self-employment before any expenses or deductions.",
      per: "Month",
    },
    {
      title: "Work Study",
      question: "What is the gross income amount of {Person} work study? (Required)",
      description: "The gross income amount of {Person}'s work study program earnings from educational institutions.",
      per: "Month",
    },
    {
      title: "SSI (Supplemental Security Income)",
      question: "What is the gross income amount of {Person} SSI (Supplemental Security Income)? (Required)",
      description: "The gross income amount of {Person}'s SSI benefits provided to people with limited income and resources who are disabled, blind, or age 65 or older.",
      per: "Month",
    },
    {
      title: "RSDI (Retirement, Survivors, and Disability Insurance)",
      question: "What is the gross income amount of {Person} RSDI (Retirement, Survivors, and Disability Insurance)? (Required)",
      description: "The gross income amount of {Person}'s RSDI benefits, also known as Social Security benefits, paid to retirees, disabled individuals, or survivors of deceased workers.",
      per: "Month",
    },
    {
      title: "Unemployment",
      question: "What is the gross income amount of {Person} unemployment? (Required)",
      description: "The gross income amount of {Person}'s unemployment benefits received from state or federal unemployment insurance programs.",
      per: "Month",
    },
    {
      title: "Child Support",
      question: "What is the gross income amount of {Person} child support? (Required)",
      description: "The gross income amount of child support payments {Person} receives to help cover the costs of raising a child.",
      per: "Month",
    },
    {
      title: "Pension",
      question: "What is the gross income amount of {Person} pension? (Required)",
      description: "The gross income amount of {Person}'s pension payments received from retirement plans or accounts.",
      per: "Month",
    },
    {
      title: "Veterans Benefits",
      question: "What is the gross income amount of {Person} veteran's benefits? (Required)",
      description: "The gross income amount of {Person}'s benefits received from the Department of Veterans Affairs for service-related compensation or pensions.",
      per: "Month",
    },
    {
      title: "Rental Income",
      question: "What is the gross income amount of {Person} rental income? (Required)",
      description: "The gross income amount of {Person}'s earnings from renting property to others before expenses are deducted.",
      per: "Month",
    },
    {
      title: "Rental Income Hours",
      question: "How many hours does {Person} work on the rental income property?",
      description: "The number of hours {Person} spends each month maintaining, managing, or working on the rental property.",
      per: "Month",
    },
    {
      title: "Workers Compensation",
      question: "What is the gross income amount of {Person} Worker's Compensation? (Required)",
      description: "The gross income amount of {Person}'s Worker's Compensation benefits received for work-related injuries or illnesses.",
      per: "Month",
    },
    {
      title: "Other",
      question: "What is the gross income amount of {Person} other income or benefits? (Required)",
      description: "The gross income amount of any other income or benefits {Person} receives that doesn't fit into the categories above.",
      per: "Month",
    },
  ];
  
  /**
   * Displays income types in a searchable list within an Ant Design modal
   * @param {Object} props - Component props
   * @param {string} props.personName - Name of the person to replace {Person} placeholder (optional)
   */
  export const showIncomeInfoModal = ({ personName = '' } = {}) => {
    const { Modal, Input, List, Typography } = require('antd');
    const { Search } = Input;
    const { Title, Text } = Typography;
    const { useState } = require('react');
    
    const IncomeInfoList = () => {
      const [searchText, setSearchText] = useState('');
      
      const filteredItems = incomeQuestionsConfig.filter(item => 
        item.title.toLowerCase().includes(searchText.toLowerCase())
      );
      
      return (
        <>
          <Search
            placeholder="Search income types..."
            onChange={e => setSearchText(e.target.value)}
            style={{ marginBottom: 16 }}
            allowClear
          />
          
          <List
            itemLayout="vertical"
            dataSource={filteredItems}
            style={{ maxHeight: '60vh', overflow: 'auto' }}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  title={item.title}
                  description={
                    <Text type="secondary">
                      {personName 
                        ? item.question.replace('{Person}', personName) 
                        : item.question}
                      <br />
                      <Text type="secondary">
                        {personName 
                          ? item.description.replace(/\{Person\}/g, personName) 
                          : item.description}
                      </Text>
                      <br />
                      <Text type="secondary" strong>Frequency: {item.per}</Text>
                    </Text>
                  }
                />
              </List.Item>
            )}
          />
        </>
      );
    };
    
    Modal.info({
      title: <Title level={4}>Income Types Information</Title>,
      width: 600,
      content: <IncomeInfoList />,
      okText: 'Close',
      icon: null,
    });
  };
  